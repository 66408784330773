/* Fortnite Button Styles */
@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');

.navigation-container {
  background-color: purple;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative; /* Changed from 'absolute' to 'relative' */
  bottom: auto; /* Removed 'bottom' property */
  right: auto; /* Removed 'right' property */
  z-index: 1;
}


.logo-container img {
  max-height: 68px;
  width: auto;
  margin-top: 8px;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.shared-button {
  height: 75px;
  width: auto;
  background: linear-gradient(#fefb72, lighten(#fefb72, 35%));
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;
  font-family: 'Luckiest Guy', cursive;
  border-radius: 0;
  box-shadow: none;
  padding: 30px;
}

.shared-button:hover {
  transform: scale(1.1);
  box-shadow: 0px 4px 15px 0px rgba(17, 17, 17, 0.8);
}

.button-inner {
  height: 75%;
  width: 90%;
  background: linear-gradient(darken(#fefb72, 35%), #fefb72);
  transform: skew(-5deg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-icon {
  width: 40px;
  height: 40px;
  margin-left: 3px;
  margin-right: 8px;
}

.button-text {
  position: relative;
  bottom: 5px;
  font-size: 3rem;
  color: #2c3076;
  margin: 15px 0 0 0;
  font-family: 'Luckiest Guy', cursive;
}

.shared-button a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  width: 100%;
  height: 100%;
}