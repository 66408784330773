@font-face {
  font-family: 'Suplexmentary Comic NC';
  src: url('./Suplexmentary\ Comic\ NC.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.image-text-container {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: purple;
    ;
  }

  .image-text-container2 {
    position: relative;
    margin-left: 50px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: purple;
  }

  .image-container {
    margin-right: 20px;
  }
  
  .image-container img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
  }
  
  .text-container {
    flex: 1;
  }
  
  .text-container h2 {
    font-family: 'Suplexmentary Comic NC', sans-serif;
    font-size: 30px;
    margin-bottom: 10px;
    color: white;
  }
  
  .text-container p {
    font-family: 'Suplexmentary Comic NC', sans-serif;
    font-size: 24px;
    line-height: 1.5;
    color: white;
  }