.rotating-banner {
    color: blueviolet;
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    place-items: center;
    padding: 10px;
    list-style-type: none;
    opacity: 1;
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      rgb(0, 0, 0) 12.5%,
      rgb(0, 0, 0) 87.5%,
      rgba(0, 0, 0, 0) 100%
    );
    overflow: hidden;
  }
  
  .banner-list {
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    place-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-indent: none;
    gap: 94px;
    position: relative;
    flex-direction: row;
    will-change: transform;
    transform: translateX(-0px);
  }
  
  .banner-item {
    flex-shrink: 0;
  }
  
  .banner-text {
    font-family: 'Fortnite', sans-serif;
    font-size: 44px;
    font-style: italic;
    font-weight: 700;
  }